import React from 'react'
import styled from 'styled-components'
import { SelectOptionType } from '..'
import SelectItem from './SelectItem'

interface SelectProps extends SelectStyleProps {
  isMultiple: boolean
  selectedOptions: OptionType[]
  setSelectedOptions?(selectionOptions: OptionType[]): void
  setValueFor?(name: string, value: string[] | string): void
  name?: string
}

interface SelectStyleProps {
  itemWidth?: string
  isInline?: boolean
}
export type OptionType = {
  value: string
  checked: boolean
  svgName?: string
  cost?: string | number
  value_id?: string
}

export const Select: React.FC<SelectProps> = ({
  isMultiple,
  itemWidth,
  isInline,
  selectedOptions,
  setSelectedOptions,
  setValueFor,
  name
}) => {
  // const [selectOptions, setSelectOptions] = useState(options)

  const handleSelectOption = (optionVaule: string) => {
    const updatedOpts = selectedOptions.map((option) => {
      if (option.value === optionVaule) {
        return { ...option, checked: isMultiple ? !option.checked : true }
      } else if (!isMultiple) {
        return { ...option, checked: false }
      }
      return option
    })

    if (setSelectedOptions) {
      setSelectedOptions(updatedOpts)
    }

    const answerValue = isMultiple
      ? formatedAnswerOfMulti(updatedOpts)
      : updatedOpts.find((item: SelectOptionType) => item.checked === true)?.value

    // console.log('_____ answer value: ', answerValue)

    if (!!name && setValueFor) {
      setValueFor(name, !!answerValue ? answerValue : '')
    }
  }

  const formatedAnswerOfMulti = (options: SelectOptionType[]) => {
    const answer: string[] = []
    options.forEach((item: SelectOptionType) => {
      if (item.checked === true) {
        answer.push(item.value)
      }
    })
    // console.log('______ answer array: ', answer)
    return answer
  }

  return (
    <OptionsContainer name={name} className='select'>
      {selectedOptions.map((option: OptionType, index: number) => (
        <SelectItem
          key={index}
          itemWidth={itemWidth}
          isInline={isInline}
          value={option.value}
          selected={option.checked}
          onSelect={() => handleSelectOption(option.value)}
          svgName={option.svgName}
        />
      ))}
    </OptionsContainer>
  )
}

Select.defaultProps = {
  isMultiple: false
}

const OptionsContainer = styled.div<Partial<SelectProps>>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  height: auto;
`

export default Select
