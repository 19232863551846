import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from '../../../theme'
import { AppStoreContext } from '../../../utils'
import { Divider, FlexContainer, Progress, Space, SVGIcon, SVGNames } from '../../atoms'
import AdditionalQuestions from './component_additionalQuestions'
import StandardQuestions from './component_standardQuestions'

export const QuestionsMobile: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const questionStore = store!.questionStore
  const [additionalQuestions, setAdditionalQuestions] = useState([] as any)

  useEffect(() => {
    // if (!!store) {
    //   store.loadQuestions().then((questions: any) => {
    setAdditionalQuestions(store!.questions)
    //   })
    // }
  }, [store])
  return (
    <div className='questions_mobile' style={{ height: '100%' }}>
      <div style={{ position: 'absolute', bottom: '-4px' }}>
        <Progress
          length={
            !!store &&
            store.questionStore.allStandardQuestionAnswered &&
            store.questionStore.showAdditionalQuestions
              ? 4 + additionalQuestions.length
              : 4
          }
          completeNumber={
            !!store
              ? store.questionStore.showAdditionalQuestions
                ? 4 + store.questionStore.additionalAnswerNumber
                : store.questionStore.standardAnswersNumber
              : 0
          }
        />
      </div>
      <Space
        pl={`${AppTheme.spacingSize.XL}px`}
        pt={`${AppTheme.spacingSize.XS}px`}
        pr={`${AppTheme.spacingSize.S}px`}>
        <VisibleContainer className='visible_container'>
          <ScrollContainer
            className='scroll_container'
            height='100%'
            isScrollable={store!.questionStore.allAccordionsClosed}
            transform={
              questionStore.isResultMobileTriggered
                ? `translateY(-${67 * (questionStore.openIndex + 1)}px)`
                : `translateY(-${67 * questionStore.openIndex}px)`
            }>
            <>
              {store!.questionStore.isResultMobileTriggered && (
                <>
                  <Space my={`${AppTheme.spacingSize.M}px`} height='auto'>
                    <FlexContainer flexDirection='row' justifyContent='flex-end'>
                      <IconContainer
                        bgColor={AppTheme.colors.secondary}
                        onClick={() => store!.questionStore.showResultMobile(true)}>
                        <SVGIcon name={SVGNames.ArrowUp} />
                      </IconContainer>
                    </FlexContainer>
                  </Space>
                  <Divider />
                </>
              )}

              <StandardQuestions />
              {!!store &&
                store.questionStore.allStandardQuestionAnswered &&
                store.questionStore.showAdditionalQuestions && (
                  <AdditionalQuestions
                    questions={additionalQuestions}
                    store={store.questionStore}
                  />
                )}
            </>
          </ScrollContainer>
        </VisibleContainer>
      </Space>
    </div>
  )
})

const VisibleContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

interface IconContainerProps {
  bgColor?: string
}

export const IconContainer = styled.div<IconContainerProps>`
  display: block;
  position: relative;
  width: 28px;
  height: 28px;
  cursor: pointer;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
`

interface ScrollContainerProps {
  height: string
  transform?: string
  isScrollable?: boolean
}

const ScrollContainer = styled.div<ScrollContainerProps>`
  height: ${(props) => props.height};
  transform: ${(props) => props.transform};
  transition: all 0.3s ease;
  overflow: ${(props) => props.isScrollable && 'scroll'};
  &::-webkit-scrollbar {
    display: none;
  }
`

QuestionsMobile.displayName = 'QuestionsMobile'
export default QuestionsMobile
