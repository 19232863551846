import { SelectOptionType } from '../../atoms'

export const AppointmentDays: SelectOptionType[] = [
  { value: 'Montag bis Freitag', checked: false }
  // { value: 'Samstag', checked: false }
]

export const AppointmentDaytime: SelectOptionType[] = [
  { value: 'Morgens', checked: false },
  { value: 'Mittags', checked: false }
  // { value: 'Abends', checked: false }
]

export const InterestedServices: SelectOptionType[] = [
  { value: 'Energieberatung', checked: false },
  { value: 'Öltankentsorgung', checked: false },
  { value: 'Anlagenprüfung', checked: false },
  { value: 'Thermographie', checked: false },
  { value: 'Fördermittelberatung', checked: false },
  { value: 'Brötchenservice', checked: false }
]
