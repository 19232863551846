import { makeAutoObservable, toJS } from 'mobx'

class UIStore {
  isContactMobileVisible: boolean = false

  public constructor() {
    makeAutoObservable(this)
  }

  triggerContactMobile = (state: boolean) => {
    this.isContactMobileVisible = state
    console.log(
      '_______ isContactOpen: ',
      this.isContactMobileVisible,
      toJS(!this.isContactMobileVisible)
    )
  }
}

export default UIStore
