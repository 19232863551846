import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import { AppTheme } from '../../../theme'
import { Card, FlexContainer, Space, Typography } from '../../atoms'
import { Contact, EmailFeedback, Questions, Result } from '../../template'
import { AppStoreContext } from '../../../utils'
import { LinkFooter } from '../../molecules'

interface HomeContentProps {
  appTitle: string
}

export const HomeContent: React.FC<HomeContentProps> = observer(({ appTitle }) => {
  const store = useContext(AppStoreContext)
  const [leftsideWidth, setLeftsideWidth] = useState(65)
  const [emailSent, setEmailSent] = useState(false)
  const [showContact, setShowContact] = useState(false)

  const handleSendEmail = () => {
    setTimeout(() => {
      setLeftsideWidth(65)
      setEmailSent(true)
    }, 2000)
  }

  const handleExpandContact = () => {
    setLeftsideWidth(showContact ? 65 : 35)
    setShowContact(!showContact)
    store!.calculateCost()
  }

  return (
    <Container>
      <LeftSideContainer width={`${leftsideWidth}%`}>
        <TitleContainer>
          <Typography
            color={readableColor(
              AppTheme.colors.primary,
              AppTheme.colors.stronggray,
              AppTheme.colors.white
            )}>
            {appTitle}
          </Typography>
        </TitleContainer>
        <Space
          marginTop={`${AppTheme.spacingSize.S + AppTheme.spacingSize.XL}px`}
          height='calc(100% - 44px)'>
          <Card>
            <Space
              py={AppTheme.spacingSize.XL}
              pr={AppTheme.spacingSize.XL}
              pl={AppTheme.spacingSize.L}>
              {!!store && !showContact ? (
                <Questions />
              ) : emailSent ? (
                <EmailFeedback />
              ) : (
                <Contact onSendEmail={handleSendEmail} />
              )}
            </Space>
          </Card>
        </Space>
      </LeftSideContainer>

      <RightSiderContainer width={`${100 - leftsideWidth}%`}>
        <Space
          marginTop={`${AppTheme.spacingSize.S + AppTheme.spacingSize.XL}px`}
          height='calc(100% - 44px)'>
          <Card>
            <Space padding={AppTheme.spacingSize.XL}>
              <FlexContainer
                style={{ height: '100%' }}
                flexDirection='column'
                justifyContent='space-between'
                alignItems='flex-end'>
                <Result
                  setExpanded={handleExpandContact}
                  expanded={showContact}
                  isEmailSent={emailSent}
                />
                <LinkFooter />
              </FlexContainer>
            </Space>
          </Card>
        </Space>
      </RightSiderContainer>
    </Container>
  )
})

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface ContainerStyleProps {
  width: string
}

const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  text-align: center;
`

const LeftSideContainer = styled.div<ContainerStyleProps>`
  width: ${(props) => props.width};
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.primary};
  transition: all 0.5s ease;
`
const RightSiderContainer = styled.div<ContainerStyleProps>`
  width: ${(props) => props.width};
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.secondary};
  transition: all 0.5s ease;
`

export default HomeContent
