/* eslint-disable array-callback-return */
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import DOMPurify from 'dompurify'
import { API } from '../api'
import {
  reformAdditionalAnswersForEmail,
  reformStandardAnswersForEmail
} from '../components/template/Questions/util'
import { AppTheme } from '../theme'
import { mapResultsToWTSettings, sumCostValueOfAdditionalAnswers } from './utils'
import QuestionStore from './QuestionStore'
import UIStore from './UIStore'

class ApplicationStore {
  api: API
  questionStore: QuestionStore
  uiStore: UIStore

  ciSettings: any
  questions: any
  wtSettings: any
  notifierSettings: any
  wcEmailSettings: any
  appName: string = ''

  userInputForCalculation: { [key: string]: { [key: string]: string } } = {
    calcServerSide: {
      BUILDING: '',
      YEAR: '',
      AREA: '',
      HEATING_TYPE: ''
    },
    calcClientSide: {
      EXTRA_ABSOLUTE: '',
      EXTRA_PROCENTUAL: ''
    }
  }

  absoluteTotal: number = 0
  procentualTotal: number = 0

  pricePerMonthForPreview: number = 0
  pricePerMonthBuyForPreview: number = 0

  calculatedPricePerMonthWithTww: number = 0
  calculatedPricePerMonthWithoutTww: number = 0
  calculatedPricePerMonthBuyWithTww: number = 0
  calculatedPricePerMonthBuyWithoutTww: number = 0

  public constructor() {
    makeAutoObservable(this)
    this.api = new API()
    this.questionStore = new QuestionStore(this)
    this.uiStore = new UIStore()
    this.loadCISettings()
    this.loadWTSettings()
    this.loadQuestions()
    this.loadWCEmailSettings()
    this.loadNotifierSettings()
  }

  loadCISettings() {
    return new Promise((resolve) => {
      this.api
        .fetchCISettings()
        .then((data: any) => {
          this.ciSettings = toJS(this.mapDataToCISettings(data))
          console.log('cisettings: ', toJS(this.ciSettings))
          this.updateThemeFromCiSettings(toJS(this.ciSettings))
          resolve(toJS(this.ciSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  loadQuestions() {
    return new Promise((resolve) => {
      this.api
        .fetchQuestions()
        .then((data: any) => {
          this.questions = this.integrateQuestionFieldProp(toJS(data).questions)
          console.log('questions_____: ', toJS(this.questions))
          resolve(toJS(this.questions))
        })
        .catch((error) => console.log(error))
    })
  }

  loadWTSettings() {
    return new Promise((resolve) => {
      this.api
        .fetchWTSettings()
        .then((data: any) => {
          runInAction(() => {
            this.wtSettings = toJS(mapResultsToWTSettings(data))
            this.appName = data.settings.settings_sw_text_heat_tool_name.question
          })
          console.log('wtSettings map result: ', toJS(this.wtSettings))
          resolve(toJS(this.wtSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  loadNotifierSettings() {
    return new Promise((resolve) => {
      this.api
        .fetchNotifierSettings()
        .then((data: any) => {
          this.notifierSettings = toJS(data.settings)
          resolve(toJS(this.notifierSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  loadWCEmailSettings() {
    return new Promise((resolve) => {
      this.api
        .fetchWCEmailSettings()
        .then((data: any) => {
          this.wcEmailSettings = toJS(data.settings)
          resolve(toJS(this.wcEmailSettings))
        })
        .catch((error) => console.log(error))
    })
  }

  mapDataToCISettings(data: any) {
    const ciSettings: { [key: string]: string | number | boolean } = {}
    Object.keys(data).map((key) => (ciSettings[`${key}`] = data[`${key}`]))
    delete ciSettings.createdAt
    delete ciSettings.updatedAt
    delete ciSettings.__v
    return ciSettings
  }

  integrateQuestionFieldProp(questions: any) {
    const updatedQuestions: { [key: string]: string | { [key: string]: string } }[] = []
    questions.map((ques: any) => {
      const updatedQ: { [key: string]: string | { [key: string]: string } } = {}
      const fields: { [key: string]: string } = {}
      Object.keys(ques).map((key) => {
        if (key.includes('field')) {
          fields[key] = ques[key]
        } else {
          updatedQ[key] = ques[key]
        }
      })
      updatedQ.fields = fields
      updatedQuestions.push(updatedQ)
    })
    console.log('new: _________', toJS(updatedQuestions), updatedQuestions)
    return updatedQuestions
  }

  updateThemeFromCiSettings(ciSettings: any) {
    AppTheme.colors.primary = ciSettings.settings_sw_color_color1
    AppTheme.colors.secondary = ciSettings.settings_sw_color_color2
  }

  calculateCost() {
    const sendToServer = { clientParams: {}, serverParams: {} }
    // let absoluteTotal = 0
    // let procentualTotal = 0
    if (
      !this.questionStore.showAdditionalQuestions ||
      (this.questionStore.showAdditionalQuestions &&
        this.questionStore.additionalAnswerNumber === 0)
    ) {
      this.absoluteTotal = 0
      this.procentualTotal = this.questionStore.selectedHeatingTypeSetting.procentualPriceExtra
    } else {
      // TODO
      const { sumAbsolute, sumProcentual } = sumCostValueOfAdditionalAnswers(
        this.questionStore.additionalAnswers
      )
      this.absoluteTotal = sumAbsolute
      this.procentualTotal =
        this.questionStore.selectedHeatingTypeSetting.procentualPriceExtra + sumProcentual
    }
    console.log('____ procentualTotal from standard: ', this.procentualTotal)

    this.userInputForCalculation.calcClientSide.EXTRA_ABSOLUTE = String(this.absoluteTotal)
    this.userInputForCalculation.calcClientSide.EXTRA_PROCENTUAL = String(this.procentualTotal)

    this.userInputForCalculation.calcServerSide = this.questionStore.calcServerSide

    sendToServer.clientParams = toJS(this.userInputForCalculation)
    sendToServer.serverParams = toJS(this.wtSettings.calculationServerParams)
    console.log('sendToServer: ', sendToServer, JSON.stringify(sendToServer))

    this.api
      .calculate(sendToServer)
      .then((data) => {
        console.log('::::::: calculate result: ', data)

        runInAction(() => {
          if (this.wtSettings.calculationServerParams.withTww) {
            this.pricePerMonthForPreview = parseInt(data.price.withTww, 10)
            this.pricePerMonthBuyForPreview = parseInt(data.buy.withTww, 10)
          } else {
            this.pricePerMonthForPreview = parseInt(data.price.withoutTww, 10)
            this.pricePerMonthBuyForPreview = parseInt(data.buy.withoutTww, 10)
          }

          this.calculatedPricePerMonthWithTww = parseInt(data.price.withTww, 10)
          this.calculatedPricePerMonthWithoutTww = parseInt(data.price.withoutTww, 10)
          this.calculatedPricePerMonthBuyWithTww = parseInt(data.buy.withTww, 10)
          this.calculatedPricePerMonthBuyWithoutTww = parseInt(data.buy.withoutTww, 10)
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  sendEmail(userInput: any) {
    const request = { data: {} as any, ...userInput }
    const { data } = request
    data.primary = reformStandardAnswersForEmail(this.questionStore.standardAnswers)
    data.secondary = reformAdditionalAnswersForEmail(this.questionStore.additionalAnswers)

    request.gdprAccepted = true
    if (!this.questionStore.priceInfo.showPrice) {
      request.calculatedPrice =
        'Setzen Sie sich für eine individuelle Beratung mit unserem Ansprechpartner in Verbindung.'
      request.hasPrice = false
    } else {
      request.calculatedPrice = this.pricePerMonthForPreview + ' €'
      request.hasPrice = true
    }

    const ciSetting = toJS(this.ciSettings)
    const emailSetting = toJS(this.wcEmailSettings)
    const notifierSetting = toJS(this.notifierSettings)

    const htmlMessage = DOMPurify.sanitize(
      emailSetting?.settings_wcemail_text_message_text?.question ?? ''
    )
    const htmlSignature = DOMPurify.sanitize(
      emailSetting?.settings_wcemail_text_signature_text?.question ?? ''
    )

    request.meta = {
      sw: {
        company: ciSetting.settings_sw_text_name,
        email: ciSetting.settings_sw_text_sw_email,
        phone: ciSetting.settings_sw_text_sw_phone,
        contact: ciSetting.settings_sw_text_sw_contact_person,
        address: {
          street: ciSetting.settings_sw_text_sw_street,
          zip: ciSetting.settings_sw_zip,
          city: ciSetting.settings_sw_text_sw_city
        },
        ci: {
          logo: process.env.APP_UPLOADS_BASE_URL + ciSetting.settings_sw_file_logo,
          primary: ciSetting.settings_sw_color_color1,
          secondary: ciSetting.settings_sw_color_color2,
          prefix_price: this.wtSettings.pricePage.text.show_before_price
        },
        template: {
          subject: emailSetting?.settings_wcemail_text_subject_text?.question,
          greeting: emailSetting?.settings_wcemail_text_greeting_text?.question,
          message: htmlMessage,
          signature: htmlSignature
        }
      },
      product: {
        name: this.wtSettings.basics.text.heatToolName,
        description_intro: notifierSetting.settings_notifier_text_intro_text.question,
        description_middle: notifierSetting.settings_notifier_text_middle_text.question,
        description_outro: notifierSetting.settings_notifier_text_outro_text.question
      }
    }

    console.log('_____ use data: ', request)
    this.api.sendEmailRequest(request)
  }
}

export const AppStore = new ApplicationStore()
export default AppStore
