import React from 'react'
import { AppTheme } from '../../../theme'
import IconEditActive from './assets/icon-edit-active.svg'
import IconEditMute from './assets/icon-edit-mute.svg'
import IconArrowUp from './assets/arrow-up.svg'
import IconArrowDown from './assets/arrow-down.svg'

export const Einfamilienhaus = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='57px'
      height='45px'
      viewBox='0 0 57 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-EFH' transform='translate(0.5 0.5)'>
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M47 27L0 27L0 0L47 0L47 27ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z'
          transform='translate(4.5 16.5)'
          id='Stroke-3'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='57px'
      height='45px'
      viewBox='0 0 57 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-EFH-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M47 27L0 27L0 0L47 0L47 27ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z'
          transform='translate(4.5 16.5)'
          id='Stroke-3'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const Mehrfamilienhaus = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='57px'
      height='61px'
      viewBox='0 0 57 61'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-MFH' transform='translate(0.5 0.5)'>
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M47 43L0 43L0 0L47 0L47 43ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11ZM19 23L8 23L8 16L19 16L19 23ZM28 23L39 23L39 16L28 16L28 23ZM8 35L19 35L19 28L8 28L8 35ZM39 35L28 35L28 28L39 28L39 35Z'
          transform='translate(4.5 16.5)'
          id='Stroke-3'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='57px'
      height='61px'
      viewBox='0 0 57 61'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-MFH-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M47 43L0 43L0 0L47 0L47 43ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11ZM19 23L8 23L8 16L19 16L19 23ZM28 23L39 23L39 16L28 16L28 23ZM8 35L19 35L19 28L8 28L8 35ZM39 35L28 35L28 28L39 28L39 35Z'
          transform='translate(4.5 16.5)'
          id='Stroke-3'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const Reihenhaus = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='65px'
      height='45px'
      viewBox='0 0 65 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-RH' transform='translate(0.5 0.5)'>
        <path
          d='M27.433 27L0 27L0 0L27.433 0L27.433 27ZM3.943 11L10.943 11L10.943 4L3.943 4L3.943 11ZM15.943 11L22.943 11L22.943 4L15.943 4L15.943 11Z'
          transform='translate(4.469 16.5)'
          id='Stroke-1'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.492 27L0 27L0 0L27.492 0L27.492 27ZM4.506 11L11.506 11L11.506 4L4.506 4L4.506 11ZM16.506 11L23.506 11L23.506 4L16.506 4L16.506 11Z'
          transform='translate(31.906 16.5)'
          id='Stroke-3'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.5 11.293L23.604 0L11.887 0L0 17'
          transform='translate(0.5 0.5)'
          id='Stroke-4'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.5 17L19.612 0L7.896 0L0 11.293'
          transform='translate(32 0.5)'
          id='Stroke-5'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='65px'
      height='45px'
      viewBox='0 0 65 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-RH-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M27.433 27L0 27L0 0L27.433 0L27.433 27ZM3.943 11L10.943 11L10.943 4L3.943 4L3.943 11ZM15.943 11L22.943 11L22.943 4L15.943 4L15.943 11Z'
          transform='translate(4.469 16.5)'
          id='Stroke-1'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.492 27L0 27L0 0L27.492 0L27.492 27ZM4.506 11L11.506 11L11.506 4L4.506 4L4.506 11ZM16.506 11L23.506 11L23.506 4L16.506 4L16.506 11Z'
          transform='translate(31.906 16.5)'
          id='Stroke-3'
          fill='none'
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.5 11.293L23.604 0L11.887 0L0 17'
          transform='translate(0.5 0.5)'
          id='Stroke-4'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.5 17L19.612 0L7.896 0L0 11.293'
          transform='translate(32 0.5)'
          id='Stroke-5'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const Wohnung = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='57px'
      height='61px'
      viewBox='0 0 57 61'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-WNG' transform='translate(0.5 0.5)'>
        <path
          d='M0 43L47 43L47 0L0 0L0 43Z'
          transform='translate(4.5 16.5)'
          id='Stroke-1'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-3'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M47 15L0 15L0 0L47 0L47 15ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z'
          transform='translate(4.5 16.5)'
          id='Stroke-5'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 5L4 5L4 0L0 0L0 5Z'
          transform='translate(51.5 26.5)'
          id='Stroke-6'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 2L4 2L4 0L0 0L0 2Z'
          transform='translate(51.5 24.5)'
          id='Stroke-8'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='57px'
      height='61px'
      viewBox='0 0 57 61'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-WNG-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M0 43L47 43L47 0L0 0L0 43Z'
          transform='translate(4.5 16.5)'
          id='Stroke-1'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-3'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M47 15L0 15L0 0L47 0L47 15ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z'
          transform='translate(4.5 16.5)'
          id='Stroke-5'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 5L4 5L4 0L0 0L0 5Z'
          transform='translate(51.5 26.5)'
          id='Stroke-6'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 2L4 2L4 0L0 0L0 2Z'
          transform='translate(51.5 24.5)'
          id='Stroke-8'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const NahFernWarm = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='57px'
      height='49px'
      viewBox='0 0 57 49'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Fernwärme' transform='translate(0.5 0.5)'>
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.5C3 2.328 2.328 3 1.5 3C0.672 3 0 2.328 0 1.5C0 0.672 0.672 0 1.5 0C2.328 0 3 0.672 3 1.5Z'
          transform='translate(33.5 20.4927)'
          id='Stroke-3'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.75 0L0 0L0 11L31 11'
          transform='translate(0.5 36.5)'
          id='Stroke-5'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 27L47 27L47 0L0 0L0 27Z'
          transform='translate(4.5 16.5)'
          id='Stroke-7'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(8.5 20.4926)'
          id='Stroke-9'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(13.5 20.4926)'
          id='Stroke-10'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(18.5 20.4926)'
          id='Stroke-11'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(23.5 20.4926)'
          id='Stroke-12'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(28.5 20.4926)'
          id='Stroke-13'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='57px'
      height='49px'
      viewBox='0 0 57 49'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Fernwärme-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M0 17L11.786 0L43.214 0L55 17'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.5C3 2.328 2.328 3 1.5 3C0.672 3 0 2.328 0 1.5C0 0.672 0.672 0 1.5 0C2.328 0 3 0.672 3 1.5Z'
          transform='translate(33.5 20.4927)'
          id='Stroke-3'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.75 0L0 0L0 11L31 11'
          transform='translate(0.5 36.5)'
          id='Stroke-5'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 27L47 27L47 0L0 0L0 27Z'
          transform='translate(4.5 16.5)'
          id='Stroke-7'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(8.5 20.4926)'
          id='Stroke-9'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(13.5 20.4926)'
          id='Stroke-10'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(18.5 20.4926)'
          id='Stroke-11'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(23.5 20.4926)'
          id='Stroke-12'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z'
          transform='translate(28.5 20.4926)'
          id='Stroke-13'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const Erdgas = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='25px'
      height='45px'
      viewBox='0 0 25 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Erdgas' transform='translate(0.5 0.5)'>
        <path
          d='M0 3L23 3L23 0L0 0L0 3Z'
          transform='translate(0.5 40.4316)'
          id='Stroke-1'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.5 0C11.5 0 0 16.89 0 23.333C0 29.459 4.657 34.473 10.573 34.953C6.315 34.536 3 31.285 3 27.333C3 23.099 11.5 12 11.5 12C11.5 12 20 23.099 20 27.333C20 31.285 16.685 34.536 12.427 34.953C18.343 34.473 23 29.459 23 23.333C23 16.89 11.5 0 11.5 0Z'
          transform='translate(0.5 0.5)'
          id='Stroke-3'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L1.854 0'
          transform='translate(11.0732 35.4526)'
          id='Stroke-4'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='25px'
      height='45px'
      viewBox='0 0 25 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Erdgas-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M0 3L23 3L23 0L0 0L0 3Z'
          transform='translate(0.5 40.4316)'
          id='Stroke-1'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.5 0C11.5 0 0 16.89 0 23.333C0 29.459 4.657 34.473 10.573 34.953C6.315 34.536 3 31.285 3 27.333C3 23.099 11.5 12 11.5 12C11.5 12 20 23.099 20 27.333C20 31.285 16.685 34.536 12.427 34.953C18.343 34.473 23 29.459 23 23.333C23 16.89 11.5 0 11.5 0Z'
          transform='translate(0.5 0.5)'
          id='Stroke-3'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L1.854 0'
          transform='translate(11.0732 35.4526)'
          id='Stroke-4'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const HeizOil = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='24px'
      height='36px'
      viewBox='0 0 24 36'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Heizöl' transform='translate(0.5 0.5)'>
        <path
          d='M0 23.333C0 16.89 11.5 0 11.5 0C11.5 0 23 16.89 23 23.333C23 29.777 17.851 35 11.5 35C5.149 35 0 29.777 0 23.333ZM16 25.5C16 26.328 16.671 27 17.5 27C18.328 27 19 26.328 19 25.5C19 24.672 18.328 24 17.5 24C16.671 24 16 24.672 16 25.5Z'
          id='Stroke-1'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='24px'
      height='36px'
      viewBox='0 0 24 36'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Heizöl-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M0 23.333C0 16.89 11.5 0 11.5 0C11.5 0 23 16.89 23 23.333C23 29.777 17.851 35 11.5 35C5.149 35 0 29.777 0 23.333ZM16 25.5C16 26.328 16.671 27 17.5 27C18.328 27 19 26.328 19 25.5C19 24.672 18.328 24 17.5 24C16.671 24 16 24.672 16 25.5Z'
          id='Stroke-1'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const Warmepumpe = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='57px'
      height='29px'
      viewBox='0 0 57 29'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Wärmepumpe' transform='translate(0.5 0.5)'>
        <path
          d='M47 27L0 27L0 0L47 0L47 27ZM4 13.5C4 18.747 8.253 23 13.5 23C18.745 23 23 18.747 23 13.5C23 8.253 18.745 4 13.5 4C8.253 4 4 8.253 4 13.5Z'
          transform='translate(8.5 0.5)'
          id='Stroke-1'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5 1.75L3.5 0L0 0L0 7L3.5 7L3.5 5.25L8 5.25L8 1.75L3.5 1.75Z'
          transform='translate(0.5 2.5)'
          id='Stroke-3'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 24)'
          id='Stroke-5'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 12)'
          id='Stroke-7'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 16)'
          id='Stroke-9'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 20)'
          id='Stroke-11'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.72205 0C7.55005 0 8.22205 0.784 8.22205 1.75C8.22205 2.71055 6.73894 6.95173 6.7222 6.99959C6.74824 6.97976 10.3354 4.24861 11.2511 3.9507C12.1701 3.6537 13.1231 4.0487 13.3801 4.8377C13.6361 5.6247 13.0981 6.5057 12.1791 6.8047C11.2616 7.10187 6.74723 7.00027 6.72216 6.9997L6.72205 7C6.72205 7 5.22205 2.716 5.22205 1.75C5.22205 0.784 5.89305 0 6.72205 0ZM0.0637535 4.8372C0.319754 4.0492 1.27275 3.6532 2.19175 3.9512C3.11075 4.2502 6.72175 7.0002 6.72175 7.0002C6.72175 7.0002 2.18375 7.1032 1.26475 6.8052C0.345754 6.5062 -0.192246 5.6252 0.0637535 4.8372ZM10.8362 12.6649C10.1662 13.1509 9.16215 12.9109 8.59415 12.1299C8.02995 11.3518 6.735 7.04267 6.72225 7.00022C6.71939 7.00975 5.41663 11.3481 4.85025 12.1302C4.28125 12.9112 3.27725 13.1512 2.60725 12.6642C1.93725 12.1772 1.85525 11.1482 2.42225 10.3662C2.98977 9.58587 6.71588 7.00462 6.72225 7.00021L6.72215 6.9999C6.72215 6.9999 10.4532 9.5839 11.0222 10.3649C11.5892 11.1479 11.5072 12.1769 10.8362 12.6649Z'
          transform='translate(15.27795 7)'
          id='Combined-Shape'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 27L19 27L19 0L0 0L0 27Z'
          transform='translate(36.5 0.5)'
          id='Stroke-23'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='57px'
      height='29px'
      viewBox='0 0 57 29'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Wärmepumpe-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M47 27L0 27L0 0L47 0L47 27ZM4 13.5C4 18.747 8.253 23 13.5 23C18.745 23 23 18.747 23 13.5C23 8.253 18.745 4 13.5 4C8.253 4 4 8.253 4 13.5Z'
          transform='translate(8.5 0.5)'
          id='Stroke-1'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5 1.75L3.5 0L0 0L0 7L3.5 7L3.5 5.25L8 5.25L8 1.75L3.5 1.75Z'
          transform='translate(0.5 2.5)'
          id='Stroke-3'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 24)'
          id='Stroke-5'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 12)'
          id='Stroke-7'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 16)'
          id='Stroke-9'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(40.5 20)'
          id='Stroke-11'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.72205 0C7.55005 0 8.22205 0.784 8.22205 1.75C8.22205 2.71055 6.73894 6.95173 6.7222 6.99959C6.74824 6.97976 10.3354 4.24861 11.2511 3.9507C12.1701 3.6537 13.1231 4.0487 13.3801 4.8377C13.6361 5.6247 13.0981 6.5057 12.1791 6.8047C11.2616 7.10187 6.74723 7.00027 6.72216 6.9997L6.72205 7C6.72205 7 5.22205 2.716 5.22205 1.75C5.22205 0.784 5.89305 0 6.72205 0ZM0.0637535 4.8372C0.319754 4.0492 1.27275 3.6532 2.19175 3.9512C3.11075 4.2502 6.72175 7.0002 6.72175 7.0002C6.72175 7.0002 2.18375 7.1032 1.26475 6.8052C0.345754 6.5062 -0.192246 5.6252 0.0637535 4.8372ZM10.8362 12.6649C10.1662 13.1509 9.16215 12.9109 8.59415 12.1299C8.02995 11.3518 6.735 7.04267 6.72225 7.00022C6.71939 7.00975 5.41663 11.3481 4.85025 12.1302C4.28125 12.9112 3.27725 13.1512 2.60725 12.6642C1.93725 12.1772 1.85525 11.1482 2.42225 10.3662C2.98977 9.58587 6.71588 7.00462 6.72225 7.00021L6.72215 6.9999C6.72215 6.9999 10.4532 9.5839 11.0222 10.3649C11.5892 11.1479 11.5072 12.1769 10.8362 12.6649Z'
          transform='translate(15.27795 7)'
          id='Combined-Shape'
          fill='none'
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 27L19 27L19 0L0 0L0 27Z'
          transform='translate(36.5 0.5)'
          id='Stroke-23'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const Elektroheizung = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='25px'
      height='45px'
      viewBox='0 0 25 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Elektrospeicher' transform='translate(0.5 0.5)'>
        <path
          d='M23 0L23 32L0 32L0 0L23 0ZM8 7.5C8 9.433 9.567 11 11.5 11C13.433 11 15 9.433 15 7.5C15 5.567 13.433 4 11.5 4C9.567 4 8 5.567 8 7.5Z'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.5 11C5.149 11 0 6.075 0 0L23 0C23 6.075 17.851 11 11.5 11ZM10 5.5C10 6.328 10.671 7 11.5 7C12.328 7 13 6.328 13 5.5C13 4.672 12.328 4 11.5 4C10.671 4 10 4.672 10 5.5Z'
          transform='translate(0.5 32.5)'
          id='Stroke-3'
          fill='none'
          fillRule='evenodd'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(4.5 28)'
          id='Stroke-5'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(4.5 24)'
          id='Stroke-7'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(4.5 20)'
          id='Stroke-9'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='25px'
      height='45px'
      viewBox='0 0 25 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Elektrospeicher-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M23 0L23 32L0 32L0 0L23 0ZM8 7.5C8 9.433 9.567 11 11.5 11C13.433 11 15 9.433 15 7.5C15 5.567 13.433 4 11.5 4C9.567 4 8 5.567 8 7.5Z'
          transform='translate(0.5 0.5)'
          id='Stroke-1'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.5 11C5.149 11 0 6.075 0 0L23 0C23 6.075 17.851 11 11.5 11ZM10 5.5C10 6.328 10.671 7 11.5 7C12.328 7 13 6.328 13 5.5C13 4.672 12.328 4 11.5 4C10.671 4 10 4.672 10 5.5Z'
          transform='translate(0.5 32.5)'
          id='Stroke-3'
          fill={`${AppTheme.colors.primary}`}
          fillRule='evenodd'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(4.5 28)'
          id='Stroke-5'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(4.5 24)'
          id='Stroke-7'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(4.5 20)'
          id='Stroke-9'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export const Holz = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg
      width='49px'
      height='24px'
      viewBox='0 0 49 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Holz' transform='translate(0.5 0.5)'>
        <path
          d='M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z'
          transform='translate(0.5 8.4961)'
          id='Stroke-1'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.883 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L8.89 11C8.647 10.25 8.506 9.403 8.506 8.504C8.506 5.467 10.073 3.004 12.006 3.004L7.999 3.004C6.639 3.004 5.462 1.781 4.883 0Z'
          transform='translate(3.9998 8.4961)'
          id='Stroke-3'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z'
          transform='translate(16.0059 11.5)'
          id='Stroke-5'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(24.3809 15)'
          id='Stroke-7'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(32.2559 19.167)'
          id='Stroke-9'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z'
          transform='translate(12.5059 11.5)'
          id='Stroke-11'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z'
          transform='translate(11.999 0.5)'
          id='Stroke-13'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(28.249 8.167)'
          id='Stroke-15'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(20.374 4)'
          id='Stroke-17'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z'
          transform='translate(8.499 0.5)'
          id='Stroke-19'
          fill='none'
          stroke='#B9B9B9'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  ) : (
    <svg
      width='49px'
      height='24px'
      viewBox='0 0 49 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='Icon-Holz-Copy' transform='translate(0.5 0.5)'>
        <path
          d='M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z'
          transform='translate(0.5 8.4961)'
          id='Stroke-1'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.883 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L8.89 11C8.647 10.25 8.506 9.403 8.506 8.504C8.506 5.467 10.073 3.004 12.006 3.004L7.999 3.004C6.639 3.004 5.462 1.781 4.883 0Z'
          transform='translate(3.9998 8.4961)'
          id='Stroke-3'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z'
          transform='translate(16.0059 11.5)'
          id='Stroke-5'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(24.3809 15)'
          id='Stroke-7'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(32.2559 19.167)'
          id='Stroke-9'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z'
          transform='translate(12.5059 11.5)'
          id='Stroke-11'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z'
          transform='translate(11.999 0.5)'
          id='Stroke-13'
          fill={`${AppTheme.colors.primary}`}
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L11 0'
          transform='translate(28.249 8.167)'
          id='Stroke-15'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0 0L15 0'
          transform='translate(20.374 4)'
          id='Stroke-17'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z'
          transform='translate(8.499 0.5)'
          id='Stroke-19'
          fill='none'
          stroke='#000000'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
export const IconEdit = ({ ...props }): JSX.Element => {
  return props.active ? <IconEditActive /> : <IconEditMute />
}

export const IconGoOn = ({ ...props }): JSX.Element => {
  return !props.active ? (
    <svg viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='button-go-on'>
        <g id='Button-Weiter-Export'>
          <path
            d='M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C32.598 42 42 32.598 42 21ZM21.4142 27.3848L25.8995 22.8995L11 22.8995C9.89543 22.8995 9 22.0041 9 20.8995C9 19.7949 9.89543 18.8995 11 18.8995L25.8995 18.8995L21.4142 14.4142C20.6332 13.6332 20.6332 12.3668 21.4142 11.5858C22.1953 10.8047 23.4616 10.8047 24.2426 11.5858L31.3137 18.6569C31.4155 18.7586 31.5039 18.8686 31.5792 18.9846C32.4013 19.233 33 19.9963 33 20.8995C33 21.8027 32.4013 22.566 31.5792 22.8144C31.5039 22.9304 31.4155 23.0404 31.3137 23.1421L24.2426 30.2132C23.4616 30.9942 22.1953 30.9942 21.4142 30.2132C20.6332 29.4322 20.6332 28.1658 21.4142 27.3848Z'
            transform='translate(6 6)'
            id='Button-Weiter'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
          <path
            d='M54 0L0 0L0 54L54 54L54 0ZM2 27C2 13.1929 13.1929 2 27 2C40.8071 2 52 13.1929 52 27C52 40.8071 40.8071 52 27 52C13.1929 52 2 40.8071 2 27Z'
            id='Combined-Shape'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Lunacy</desc>
      <g id='button-go-on'>
        <g id='Button-Weiter-Export'>
          <path
            d='M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C32.598 42 42 32.598 42 21ZM21.4142 27.3848L25.8995 22.8995L11 22.8995C9.89543 22.8995 9 22.0041 9 20.8995C9 19.7949 9.89543 18.8995 11 18.8995L25.8995 18.8995L21.4142 14.4142C20.6332 13.6332 20.6332 12.3668 21.4142 11.5858C22.1953 10.8047 23.4616 10.8047 24.2426 11.5858L31.3137 18.6569C31.4155 18.7586 31.5039 18.8686 31.5792 18.9846C32.4013 19.233 33 19.9963 33 20.8995C33 21.8027 32.4013 22.566 31.5792 22.8144C31.5039 22.9304 31.4155 23.0404 31.3137 23.1421L24.2426 30.2132C23.4616 30.9942 22.1953 30.9942 21.4142 30.2132C20.6332 29.4322 20.6332 28.1658 21.4142 27.3848Z'
            transform='translate(6 6)'
            id='Button-Weiter'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
          <path
            d='M54 0L0 0L0 54L54 54L54 0ZM2 27C2 13.1929 13.1929 2 27 2C40.8071 2 52 13.1929 52 27C52 40.8071 40.8071 52 27 52C13.1929 52 2 40.8071 2 27Z'
            id='Combined-Shape'
            fill='#FFFFFF'
            fillRule='evenodd'
            stroke='none'
          />
        </g>
      </g>
    </svg>
  )
}

export const IconShowResult = (): JSX.Element => {
  return (
    <svg viewBox='0 0 54 54' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Button_direktzumAngebot' fill='#FFFFFF'>
          <path
            d='M0,0 L54,0 L54,54 L0,54 L0,0 Z M27,2 C13.1928813,2 2,13.1928813 2,27 C2,40.8071187 13.1928813,52 27,52 C40.8071187,52 52,40.8071187 52,27 C52,13.1928813 40.8071187,2 27,2 Z'
            id='Combined-Shape'></path>
          <path
            d='M27,6 C38.5979797,6 48,15.4020203 48,27 C48,38.5979797 38.5979797,48 27,48 C15.4020203,48 6,38.5979797 6,27 C6,15.4020203 15.4020203,6 27,6 Z M32,19 C32.553,19 33,18.553 33,18 C33,17.447 32.553,17 32,17 L22,17 C21.447,17 21,17.447 21,18 C21,18.553 21.447,19 22,19 L32,19 Z M33,22 C33,21.447 32.553,21 32,21 L22,21 C21.447,21 21,21.447 21,22 C21,22.553 21.447,23 22,23 L32,23 C32.553,23 33,22.553 33,22 Z M33,26 C33,25.447 32.553,25 32,25 L22,25 C21.447,25 21,25.447 21,26 C21,26.553 21.447,27 22,27 L32,27 C32.553,27 33,26.553 33,26 Z M27,36 C27,35.447 26.553,35 26,35 L22,35 C21.447,35 21,35.447 21,36 C21,36.553 21.447,37 22,37 L26,37 C26.553,37 27,36.553 27,36 Z M41,19.2676 C40.044,18.7156 38.82,19.0426 38.268,19.9996 L31.768,31.2576 L35.232,33.2576 L41.732,21.9996 C42.284,21.0426 41.957,19.8206 41,19.2676 Z M35,35.6602 L35,38.8002 C35,38.9102 34.91,39.0002 34.8,39.0002 L19.2,39.0002 C19.09,39.0002 19,38.9102 19,38.8002 L19,15.2002 C19,15.0902 19.09,15.0002 19.2,15.0002 L34.8,15.0002 C34.91,15.0002 35,15.0902 35,15.2002 L35,23.6592 L37,20.1952 L37,15.0002 C37,13.8952 36.104,13.0002 35,13.0002 L19,13.0002 C17.896,13.0002 17,13.8952 17,15.0002 L17,39.0002 C17,40.1042 17.896,41.0002 19,41.0002 L35,41.0002 C36.104,41.0002 37,40.1042 37,39.0002 L37,32.1972 L35,35.6602 Z M31,36.5879 L34.732,34.1239 L31.268,32.1239 L31,36.5879 Z M29,30 C29,29.447 28.553,29 28,29 L22,29 C21.447,29 21,29.447 21,30 C21,30.553 21.447,31 22,31 L28,31 C28.553,31 29,30.553 29,30 Z'
            id='Combined-Shape'></path>
        </g>
      </g>
    </svg>
  )
}

export const ArrowUp = ({ ...props }): JSX.Element => {
  return <IconArrowUp {...props} />
}

export const ArrowDown = ({ ...props }): JSX.Element => {
  return <IconArrowDown {...props} />
}

export const SVGType = {
  Einfamilienhaus,
  Mehrfamilienhaus,
  Reihenhaus,
  Wohnung,
  NahFernWarm,
  Erdgas,
  HeizOil,
  Warmepumpe,
  Elektroheizung,
  Holz,
  IconEdit,
  IconGoOn,
  IconShowResult,
  ArrowUp,
  ArrowDown
}

export enum SVGNames {
  Einfamilienhaus = 'Einfamilienhaus',
  Mehrfamilienhaus = 'Mehrfamilienhaus',
  Reihenhaus = 'Reihenhaus',
  Wohnung = 'Wohnung',
  NahFernWarm = 'NahFernWarm',
  Erdgas = 'Erdgas',
  HeizOil = 'HeizOil',
  Warmepumpe = 'Warmepumpe',
  Elektroheizung = 'Elektroheizung',
  Holz = 'Holz',
  IconEdit = 'IconEdit',
  IconGoOn = 'IconGoOn',
  IconShowResult = 'IconShowResult',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp'
}
