/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect, ChangeEvent, useContext, Fragment } from 'react'
import QuestionStore from '../../../store/QuestionStore'
import { AdditionalAnswerType } from '../../../store/static'
import { AppTheme } from '../../../theme'
import { DeviceContext, DeviceType } from '../../../utils'
import { RangeSliderWithCustomStep, Select, SelectOptionType, Space, TextField } from '../../atoms'
import { Accordion, AccordionMobile } from '../../molecules'
import { transferFieldsToSelectOption, transferFieldsToSteps } from './util'

interface AdditionalQuestionsProps {
  questions: any
  store: QuestionStore
}

export const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = observer(
  ({ questions, store }) => {
    const { device } = useContext(DeviceContext)
    const renderAccordion = (item: any, index: number) => {
      const reformatedAnswer = () => {
        const value = store.additionalAnswers.find((answer) => answer.question === item.question)!
          .value
        return typeof value === 'string' ? value : value.join(', ')
      }
      return (
        <>
          {device === DeviceType.MOBILE ? (
            <AccordionMobile
              title={item.question}
              description={item.questionLong}
              isOpen={
                store.accordionActiveStatesForAdditional.length > 0
                  ? store.accordionActiveStatesForAdditional[index].active
                  : item.order === 1
              }
              onHide={store.openNextAccordion}
              onOpen={store.openOneAccordionAndCloseOthers}
              key={item.id}
              index={+item.order - 1 + 4}
              noteUnderTitle={reformatedAnswer()}
              isLast={index === questions.length - 1}
              showContinueBtn={true}
              isContinueBtnActive={true}
              showResultBtn={true}>
              <Space
                mb={
                  item.presentation === 'text' || item.type === 'text'
                    ? `${AppTheme.spacingSize.XL}px`
                    : `${AppTheme.spacingSize.L}px`
                }>
                <SingleQuestion question={item} store={store} />
              </Space>
            </AccordionMobile>
          ) : (
            <Accordion
              title={item.question}
              description={item.questionLong}
              isOpen={
                store.accordionActiveStatesForAdditional.length > 0
                  ? store.accordionActiveStatesForAdditional[index].active
                  : item.order === 1
              }
              onClose={store.openNextAccordion}
              onOpen={store.openOneAccordionAndCloseOthers}
              key={item.id}
              index={+item.order - 1 + 4}
              noteUnderTitle={reformatedAnswer()}
              showContinueBtn={+item.order < questions.length}>
              <Space
                mb={
                  item.presentation === 'text' || item.type === 'text'
                    ? `${AppTheme.spacingSize.XL}px`
                    : `${AppTheme.spacingSize.L}px`
                }>
                <SingleQuestion question={item} store={store} />
              </Space>
            </Accordion>
          )}
        </>
      )
    }

    return (
      <div className='additional_questions'>
        {questions.map((item: any, index: number) => (
          <Fragment key={index}>{renderAccordion(item, index)}</Fragment>
        ))}
      </div>
    )
  }
)

interface SingleQuestionProps {
  question: any
  store: QuestionStore
}

const SingleQuestion: React.FC<SingleQuestionProps> = ({ question, store }) => {
  const [options, setOptions] = useState([] as SelectOptionType[])
  const [stepValue, setStepValue] = useState(
    Math.floor((transferFieldsToSteps(question.fields).length - 1) / 2)
  )
  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    if (question.presentation === 'cloud') {
      setOptions(transferFieldsToSelectOption(question.fields))
    } else if (question.presentation === 'slider') {
      setStepValue(Math.floor((transferFieldsToSteps(question.fields).length - 1) / 2))
    }
  }, [question.presentation, question.fields])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value)
  }

  const renderQuestion = () => {
    switch (question.presentation || question.type) {
      case 'cloud':
      case 'multi':
        const isMulti = question.selectSubType === 'multi'
        return (
          <Select
            isMultiple={isMulti}
            name={question.question}
            selectedOptions={options as SelectOptionType[]}
            setSelectedOptions={setOptions}
            setValueFor={store.updateAdditionalAnswers}
          />
        )

      case 'slider':
        const answer: string = store.additionalAnswers.find(
          (elem: AdditionalAnswerType) => elem.id === question.id
        )!.value as string
        return (
          <RangeSliderWithCustomStep
            name={question.question}
            steps={transferFieldsToSteps(question.fields)}
            value={stepValue}
            setValue={setStepValue}
            setValueFor={store.updateAdditionalAnswers}
            output={(answer as string) === '' ? 'Bitte wählen' : answer}
          />
        )

      case 'text':
        return (
          <TextField
            name={question.question}
            value={textValue}
            onInputChange={handleInputChange}
            setValueFor={store.updateAdditionalAnswers}
          />
        )

      default:
        return null
    }
  }
  return <>{renderQuestion()}</>
}

SingleQuestion.displayName = 'SingleQuestion'
AdditionalQuestions.displayName = 'AdditionalQuestions'

export default AdditionalQuestions
