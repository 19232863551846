import React, { ReactElement } from 'react'
import styled from 'styled-components'

interface FlexContainerProps extends FlexStylesProps {
  children: ReactElement | ReactElement[]
  className?: string
  onClick?(): void
}

export const FlexContainer: React.FC<FlexContainerProps> = ({
  children,
  onClick,
  className,
  ...props
}) => {
  return (
    <StyledFlex onClick={onClick} className={className} {...props}>
      {children}
    </StyledFlex>
  )
}

interface FlexStylesProps {
  bgColor?: string
  justifyContent?: string
  flexDirection?: string
  alignItems?: string
  style?: { [key: string]: string }
}

const StyledFlex = styled.div<FlexStylesProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  background-color: ${(props) => props.bgColor};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  ${(props) => props.style};
`

export default FlexContainer
