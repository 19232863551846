import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AppTheme } from '../../../theme'
import { AppStoreContext } from '../../../utils'
import { Progress, Space } from '../../atoms'
import StandardQuestions from './component_standardQuestions'
import AdditionalQuestions from './component_additionalQuestions'

const Questions: React.FC = observer(() => {
  const store = useContext(AppStoreContext)
  const [additionalQuestions, setAdditionalQuestions] = useState([] as any)

  useEffect(() => {
    setAdditionalQuestions(store!.questions)
  }, [store])

  return (
    <div className='questions'>
      <div style={{ position: 'absolute' }}>
        <Progress
          length={
            !!store &&
            store.questionStore.allStandardQuestionAnswered &&
            store.questionStore.showAdditionalQuestions
              ? 4 + additionalQuestions.length
              : 4
          }
          completeNumber={
            !!store
              ? store.questionStore.showAdditionalQuestions
                ? 4 + store.questionStore.additionalAnswerNumber
                : store.questionStore.standardAnswersNumber
              : 0
          }
        />
      </div>
      <Space pl={`${AppTheme.spacingSize.XL}px`}>
        <StandardQuestions />
        {!!store &&
          store.questionStore.allStandardQuestionAnswered &&
          store.questionStore.showAdditionalQuestions && (
            <AdditionalQuestions questions={additionalQuestions} store={store.questionStore} />
          )}
      </Space>
    </div>
  )
})

Questions.displayName = 'Questions'
export default Questions
