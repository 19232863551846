import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppTheme } from '../../../theme'
import { AppStoreContext } from '../../../utils'
import { Link, Space, Typography } from '../../atoms'

export const LinkFooter: React.FC = () => {
  const store = useContext(AppStoreContext)

  return (
    <FooterContainer>
      {!!store?.wtSettings?.form.text.dataProtectionUrl && (
        <Link target='_blank' href={store?.wtSettings?.form.text.dataProtectionUrl}>
          <Typography
            color={AppTheme.colors.darkgray}
            fontSize={`${AppTheme.fonts.sizes.size_Button}px`}>
            Datenschutz
          </Typography>
        </Link>
      )}
      {!!store?.wtSettings?.form.text.dataImprintUrl && (
        <Space ml={AppTheme.spacingSize.S}>
          <Link target='_blank' href={store?.wtSettings?.form.text.dataImprintUrl}>
            <Typography
              color={AppTheme.colors.darkgray}
              fontSize={`${AppTheme.fonts.sizes.size_Button}px`}>
              Impressum
            </Typography>
          </Link>
        </Space>
      )}
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
