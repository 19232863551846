import React from 'react'
import styled from 'styled-components'
import { SVGNames, SVGType } from './SVGLib'

interface SVGIconProps {
  name: string
  active?: boolean
  className?: string
}
export const SVGIcon: React.FC<SVGIconProps> = ({ name, active, className }) => {
  const renderSVG = (svgName: string) => {
    switch (svgName) {
      case SVGNames.Einfamilienhaus:
        return <SVGType.Einfamilienhaus active={active} />
      case SVGNames.Mehrfamilienhaus:
        return <SVGType.Mehrfamilienhaus active={active} />
      case SVGNames.Reihenhaus:
        return <SVGType.Reihenhaus active={active} />
      case SVGNames.Wohnung:
        return <SVGType.Wohnung active={active} />
      case SVGNames.NahFernWarm:
        return <SVGType.NahFernWarm active={active} />
      case SVGNames.Erdgas:
        return <SVGType.Erdgas active={active} />
      case SVGNames.HeizOil:
        return <SVGType.HeizOil active={active} />
      case SVGNames.Warmepumpe:
        return <SVGType.Warmepumpe active={active} />
      case SVGNames.Elektroheizung:
        return <SVGType.Elektroheizung active={active} />
      case SVGNames.Holz:
        return <SVGType.Holz active={active} />
      case SVGNames.IconEdit:
        return <SVGType.IconEdit active={active} />
      case SVGNames.IconGoOn:
        return <SVGType.IconGoOn active={active} />
      case SVGNames.IconShowResult:
        return <SVGType.IconShowResult />
      case SVGNames.ArrowDown:
        return <SVGType.ArrowDown />
      case SVGNames.ArrowUp:
        return <SVGType.ArrowUp />
      default:
        return null
    }
  }

  return <SVGContainer className={`svg_icon ${className}`}>{renderSVG(name)}</SVGContainer>
}

const SVGContainer = styled.div`
  display: block;
  cursor: pointer;
  text-align: center;
`

export default SVGIcon
