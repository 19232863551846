import React, { ReactElement, ReactNode, createContext } from 'react'
import { AppStore } from '../../store'

export const AppStoreContext = createContext<typeof AppStore | null>(AppStore)

// create ContextProvider
interface AppStoreProviderProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}
export const StoreContextProvider: React.FC<AppStoreProviderProps> = ({ children }) => {
  return <AppStoreContext.Provider value={AppStore}>{children}</AppStoreContext.Provider>
}

export default AppStoreContext
