import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { AppTheme, Displays } from '../../../theme'
import { AppStoreContext, getUploadURL } from '../../../utils'
import { Image, Space, Typography } from '../../atoms'

export const EmailFeedback: React.FC = () => {
  const store = useContext(AppStoreContext)
  const [feedbackText, setFeedbackText] = useState('')
  const [logoURL, setLogoURL] = useState('')

  useEffect(() => {
    if (!!store) {
      setFeedbackText(
        !!store.wtSettings.form.text.sendSuccess
          ? store.wtSettings.form.text.sendSuccess
          : 'Ihre Daten wurden erfolgreich an uns übermittelt.'
      )
      setLogoURL(getUploadURL + store.ciSettings.settings_sw_file_logo)
    }
  }, [getUploadURL, store])

  return (
    <FeedbackContainer>
      <Space pt={`${AppTheme.spacingSize.XS}px`} pb={`${AppTheme.spacingSize.M}px`} height='auto'>
        <ImageContainer>
          <Image src={logoURL} display='block' maxWidth='305px' width='305px' height='100%' />
        </ImageContainer>
      </Space>
      <TextContainer>
        <Typography
          fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
          display={Displays.BLOCK}
          lineHeight='3rem'>
          {'Vielen Dank!'}
        </Typography>
        <Typography
          lineHeight='1.5rem'
          fontSize={`${AppTheme.fonts.sizes.size_Paragraph_L}px`}
          display={Displays.BLOCK}>
          {feedbackText}
        </Typography>
      </TextContainer>
    </FeedbackContainer>
  )
}

const FeedbackContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkgray};
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TextContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkgray};
`

export default EmailFeedback
